@import "../../../styles/varible";
@import "../../../styles/common.scss";

.news-detail {
    &-content {
        padding: 0 $page-padding-x-lg;
        display: flex;
        justify-content: center;
        padding-top:30px;
        &>div {
            display: inline-block;
           // background-color: #fff;
            max-width: calc(100vw - $page-padding-x-lg*2);
        }

        img {
            max-width: calc(100vw - $page-padding-x-lg*2) !important;
            object-fit: scale-down !important;
            height: auto !important;
        }
    }

    @media(max-width:768px) {
        &-content {
            padding: $page-padding-horizontal;

            &>div {
                max-width: calc(100vw - $page-padding-horizontal*2);
            }

            img {
                max-width: calc(100vw - $page-padding-horizontal*2) !important;
            }
        }
    }
}