@import "../../styles/common.scss";

.cell-title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 0;
    position: relative;

    h3 {
        font-size: 26px;
        margin: 0;
        color: #333;
        font-weight: 600;
        margin-bottom: 3px;
    }

    &-english {
        color: #333;
        font-size: 14px;
    }

    &-other {
        position: absolute;
        right: 30px;
        bottom: 0;
        font-size: 16px;
        cursor: pointer;
        text-decoration: underline;
        p{
            width: 100%;
        }
    }
}

.cell-title-line {
    box-sizing: content-box;

    &__right {
        .cell-title-line__title {
            .main {
                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 4px;
                    height: 100%;
                    background-color: #0066ff;
                }
            }
        }
    }

    &__left {

        .cell-title-line__title {
            .main {
                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    width: 4px;
                    height: 100%;
                    background-color: #0066ff;
                }
            }
        }
    }

    &__title {
        display: flex;
        align-items: center;
        color: #333;

        .main {
            position: relative;
            padding-left: 12px;
            font-weight: 550;
            font-size: 22px;
            white-space: nowrap;
        }

        .sub {
            font-size: 14px;
            margin-left: 10px;
            text-decoration: underline;
        }
    }

    &__middle {
        .cell-title-line__title {
            .main {
                font-size: 16px;
                width: 3px;
            }

            .main-caret {
                display: inline-block;
                cursor: pointer;
                @include noselect();
                &::after {
                    content: "";
                    border-bottom: 0;
                    border-left: .3em solid #0000;
                    border-right: .3em solid #0000;
                    border-top: .3em solid;

                    display: inline-block;
                    margin-left: .3em;
                    vertical-align: .2em;
                }
                &-up{
                    &::after {
                        content: "";
                        border-bottom: .3em solid;
                        border-left: .3em solid #0000;
                        border-right: .3em solid #0000;
                        border-top: 0;
    
                        display: inline-block;
                        margin-left: .3em;
                        vertical-align: .2em;
                    }
                }

            }

            // .main {
            //     &::before {
            //         content: '';
            //         position: absolute;
            //         top: 0;
            //         left: 0;
            //         width: 4px;
            //         height: 100%;
            //         background-color: #0066ff;
            //     }
            // }
        }
    }

    &__middle,
    &__left {
        .cell-title-line__title {
            .main {
                &::before {
                    // display: block;
                    height: 80%;
                    // width: 3px;
                }
            }
        }
    }

    &__small {
        .cell-title-line__title {
            .main {
                font-size: 14px;
                width: 3px;
            }


        }
    }
}

.cell-title-head {
    display: inline-flex;
    flex-direction: column;
    &__main {
        margin-top: 1.5rem;
        font-size: 26px;
        color: #333;
        font-weight: 530;
        display: flex;
        align-items: center;

        &-suffix {
            font-size: 14px;
            color: #333;
            transform: scale(0.8);
            cursor: pointer;
            padding: 4px 12px;
            font-weight: 500;
            background-color: #fff;
            border-radius: 100px;
            box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
        }
    }

    &__english {
        font-size: 14px;
        font-weight: 200;
        color: #222;
        margin-bottom: 5px;
    }
}

@media(max-width:768px) {
    .cell-title {
        h3 {
            font-size: 18px;
        }

        &-other {
            right: 15px;
        }

        &--small {
            margin: 20px 0;
        }
    }

    .cell-title-line {
        &__title {
            .main {
                font-size: 18px;
            }
        }
    }

    .cell-title-head {
        padding-top: 10px;

        &__main {
            font-size: 18px;
            color: #333;
            font-weight: 530;

            &-suffix {
                font-size: 12px;
                transform: scale(0.8);
            }
        }

        &__english {
            font-size: 14px;
            font-weight: 200;
            color: #222;
            margin-bottom: 0;
        }
    }
}