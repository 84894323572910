@import "../../../styles/varible";
@import "../../../styles/common.scss";

.join-detail-page {
    &-content {
        @include cover("../../../assets/join/img_join-bg1.png");
        padding: 0 $page-padding-x-lg;

        &--detail {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            // top:50%;
            padding-top: calc(13vw - 46px);

            .cell-title-head__english {
                margin-bottom: 25px;
            }
        }
    }

    .jc-tab {
        margin-bottom: 30px;
    }

    p {
        font-size: 16px;
        color: #222;
        font-weight: 345;
        margin-bottom: 5px;
        width: 100%;
        width: 800px;
        // margin-top: 10px;
        // margin-bottom: 20px;
        // padding-right: 20px;
    }

    @media(max-width:768px) {
        .jc-tab {
            margin-bottom: 20px;
        }

        div.cell-title-head__main {
           // margin: 0 !important;
           margin-top: 20px !important;
        }

        &-content {
            padding: 0 $page-padding-horizontal;
            height: $mobile-bg-height;

            &--detail {
                width: calc(100vw - $page-padding-horizontal*2);
                box-sizing: border-box;

            }

        }

        &-wrapper {
            &>div:first-child {
                height: 90vw;
            }

        }

        p {
            text-align: justify;
            font-size: 12px;
            margin-top: 8px;
            margin-bottom: 10px;
            width: auto;
            width: 100%;
        }
    }
}

.table-wrapper {
    padding: 0 var(--table-wrapper-x, $page-padding-x-lg);

    table {
        width: 100%;
        // box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
        background-color: #fff;

        th {
            background-color: #eee;
            padding: 12px 30px;
            font-size: 18px;
            font-weight: 520;
        }

        td {
            border-top: 1px solid #ddd;
            border-collapse: collapse;
            padding: 25px 30px;
            font-size: 16px;
            font-weight: 300;
        }

        tbody tr {
            cursor: pointer;
        }
    }
}

.join-positon-card {
    margin: 0 $page-padding-horizontal;
    padding: 20px calc($page-padding-x-lg - $page-padding-horizontal);
    background-color: #fff;
    border-radius: 6px;

    h5 {
        font-size: 16px;
    }

    &--tag {
        display: flex;
        font-size: 12px;
        padding: 10px 0 15px;

        &>div {
            @include tag();
        }
    }

    &--date {
        font-size: 14px;
        color: #999;
    }

    // &:not(:last-child){
    //     border-bottom: 1px solid #ccc;
    // }
    margin-bottom: 15px;

    // &:last-child{
    //     margin-bottom: 10px;
    // }
    &:last-child {
        margin-bottom: 30px;
    }
}