@import "../../../styles/varible";
@import "../../../styles/common.scss";

.product-page {
    &-content {
        // @include cover("../../../assets/products/img_products-bg.png");
        padding: 0 $page-padding-x-lg;
    }

    &-detail {
        padding: 0 calc($page-padding-x-lg - 10px);

        &>div {
            padding: 0 15px;
        }
    }

    @media(max-width:768px) {
        &-content {
            padding: 0 $page-padding-horizontal;
            height: $mobile-bg-height;
        }

        &-detail {
            padding: 0;
        }
    }
}

.product-card-wrapper {
    // padding: 20px 20px 20px 100px;
    // background-color: #fff;
    // margin: 10px 0;
    // box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
    // border-radius: 10px;
    // border:1px solid red;
    position: relative;
    .product-card {
        cursor: pointer;
        margin:20px 15px;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        display: flex;
        background-color: #fff;
        justify-content: space-between;
        padding:0px 30px 0 100px;
        position: relative;
        &-left{
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-grow: 1;
        }
      
        overflow: hidden;

        &-img {
            &__wrapper {
                height: 200px;
                width: 300px;
                img {
                    height: 200px;
                    margin:0 auto;
                }
            }
        }

        &-title {
            font-size: 14px;
            font-weight: 550;
            white-space: nowrap;
        }

        &-tag {
            display: flex;
            font-size: 12px;
            margin-top: 15px;
            max-width: 40vw;
            flex-wrap: wrap;

            &>div {
                @include tag(10px);
                margin-bottom: 10px;
            }
        }

        &-des {
            font-size: 12px;
            color: #666;
            max-width: 40vw;
            margin-top: 20px;
            text-align: justify;
        }

        &-btn {
            cursor: pointer;
            font-size: 14px;
            display: inline-block;
            color: #0066ff;
            border:1px solid #0066ff;
            padding: 2px 10px;
            border-radius: 3px;
            margin-top: 30px;
        }
    }
    @media(max-width:767px) {
        padding: 10px;

        .product-card {
            cursor: pointer;
            display: flex;
            margin:0px !important;
            padding:0 15px !important;
            &>div:first-child {
                flex-basis: 60%;
                max-width: calc(70vw - 80px);
                padding-right: 10px;
            }
            &>div:last-child {
                flex-grow: 1;
                position: relative;
                min-width: 30vw ;
                min-height: 40vw;
            }
            &-position{
                position: absolute;
                top:10px;
                left:10px;
                width: calc(100% - 20px);
                height: calc(100% - 20px);
            }
            &-img {
                &__wrapper {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-position: center !important;
                    background-repeat: no-repeat !important;
                    background-size: contain !important;
                }
            }

            &-tag {
                display: flex;
                font-size: 12px;
                margin-top: 5px;
                max-width: 57vw;
                flex-wrap: wrap;

                &>div {
                    @include tag(5px, 5px);
                    margin-bottom: 5px;
                }
            }

            &-title {
                max-width: 56vw;
            }

            &-des {
                max-width: 55vw;
            }

            &-btn {
                margin-top: 15px;
            }
        }
    }
}


.jc-collpase {
    height: 0;
    transition: height .5s;
    overflow: hidden;
}

.jc-expand {
    height: auto;
    transition: height .5s;
    overflow: hidden;
}