.count-content{
    position: absolute;
    color: #333;
    left:50%;
    transform: translateX(-50%);
    bottom: 20px;
    background: rgba(255,255,255,0.4);
    border-radius: 10px;
    overflow: hidden;
    &-item{
        flex-grow: 1;
       // gap: 15px;
        padding:5px 15px;
        &__right{
            line-height: 1.2;
            &--title{
                white-space: nowrap;
                font-size: 14px;
            }
            &--number{
                font-size: 16px;
                font-weight: 500;
                text-align: right;
            }
            &--unit{
                font-size: 12px;

            }
        }
    }
}

@media screen and (min-width:768px) {
    .count-content{
        position: absolute;
        color: #333;
        left:50%;
        transform: translateX(-50%);
        bottom: 8vh;
        background: rgba(255,255,255,0.4);
        border-radius: 10px;
        overflow: hidden;
        &-item{
            gap: 15px;
            padding:8px 35px;
            &__left{
                font-size: 14px;
                img{
                    //width: 33px;
                    height: 36px;
                }
                &>div{
                    margin-top: 2px;
                    white-space: nowrap;
                }
            }
            &__right{
                &--number{
                    font-size: 24px;
                    font-weight: 600;
                }
                &--unit{
                    font-size: 16px;
                }
            }
        }
        &-introduce{
            font-size: 22px;
            font-weight: 550;
            white-space: nowrap;
            padding:8px 35px;
            position: relative;
            &::after{
                content: "";
                position: absolute;
                right: 0;
                top:50%;
                transform: translateY(-50%);
                height: 70%;
                width: 1px;
                background-color: #333;
            }
        }
    }
    
}