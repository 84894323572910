@import "../../../styles/varible";
@import "../../../styles/common.scss";

.product-detail-page{
    div.row{
        --bs-gutter-x:0;
        padding:15px $page-padding-x-lg;
    }
    &-content {
        @include cover();
        padding: 0 $page-padding-x-lg;
    }
    &-title{
        font-size: 22px;
        font-weight: 530;
        letter-spacing: 1px;
        position: absolute;
        left:50%;
        transform: translateX(-50%);
        top:10vh;
        // white-space: nowrap;
        text-align: center;
        width: 95vw;
        &::after{
            content: "";
            position: absolute;
            bottom: -5px;
            width: 70px;
            height: 2px;
            background-color: #0066ff;
            left:50%;
            transform: translateX(-50%);
        }
    }
    @media(max-width:768px) {
        div.row{
            padding:15px;
        }
        &-content {
            padding: 0 $page-padding-horizontal;
            height: 30vh;
            // background-size:contain;
        }
        &-title{
            width: 85vw;
            font-size: 16px;
            top:40px;
            line-height: 1.4;
        }
        &-detail {
            padding: 0;
        }
        .special{
            &>div:first-child{
                background-position: calc(50% + 50px) 50%;
            }
          }
    }
}