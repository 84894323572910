@import "./varible";

@mixin filter-backdrop{
    background: $nav-bg-color;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}

@mixin scroll {
    &::-webkit-scrollbar {
        width: 6px;
        height: 10px;
    }
    &::-webkit-scrollbar-track {
        border-radius: 10px;
        -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
    }
    &::-webkit-scrollbar-thumb {
        background: #0000001a;
        border-radius: 10px;
        // border-left: 4px solid red;
        -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
        // background-clip: padding-box;
        // margin-left: 4px;
        transform: translateX(5px);
    }
    &::-webkit-scrollbar-thumb:window-inactive {
        background: rgba(0, 0, 0, 0.1);
    }
}

@mixin ellipse($line:1) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
}

@mixin cover($url:""){
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    @if($url!=""){
        background-image: url($url);
    }
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    position: relative;
}

@mixin tag($me:15px,$px:10px){
    margin-right: $me;
    padding: 0 $px;
    border-radius: 5px;
    background-color: #eee;
    color:#222;
    white-space: nowrap;
}

@mixin noselect(){
    outline: 0px;
    -moz-user-select: none;
    /*mozilar*/
    -webkit-user-select: none;
    /*webkit*/
    -ms-user-select: none;
    /*IE*/
    user-select: none;
    text-decoration: none;
}