@import "../../styles/varible";
@import "../../styles/common.scss";

.solution-page {
    padding-bottom: 20px;

    &-solution {
        &__content {
            width: calc(100vw - $page-padding-x-lg * 2);
            font-size: 18px;
            text-align: justify;
            padding-left: 20px;
            color: #666;
            box-sizing: border-box;
        }

        &__description {
            font-size: 18px;
            color: #333;
            position: relative;
            padding-left: calc($page-padding-x-lg + 20px);

            &-font {
                font-size: 20px;
                margin-top: 10px;
            }

            &-block {
                background-color: #1e4a8e;
                border-radius: 12px;
                display: inline-block;
                padding: 8px 15px;
                margin-top: 5px;
                color: #fff;
            }
        }

        &__img {
            width: 100vw;
        }
    }

    &-card {
        padding: 0 $page-padding-x-lg;

        &__title {
            >div:first-of-type {
                position: relative;
                display: flex;
                align-items: flex-end;
                line-height: 1;

                span {
                    color: #0066ff;
                    font-family: Arial Black;
                    font-size: 60px;
                    opacity: 0.4;
                    font-weight: bolder;
                }

                h3 {
                    margin-bottom: 0;
                    position: absolute;
                    left: 20px;
                    bottom: 5px;
                    font-size: 28px;
                    color: #333;
                }
            }

            p {
                padding-left: 20px;
                color: #666;
            }
        }

        &__content {
            margin-left: 20px;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        .solution-page-card__item {
            text-align: center;
            justify-content: space-around;
            position: relative;
            font-size: 15px;

            >div {
                color: #333333;

            }

            img {
                width: 100%;
            }

            .customer-img {
                position: absolute;
                left: 120%;
                width: 200%;
                bottom: 0;
            }
        }

        .swiper-pagination {
            display: flex;
            justify-content: center;
            margin-top: 15px;

            &-bullet {
                width: 10px;
                height: 2px;
                display: inline-block;
                background-color: #ccc;
                margin-right: 10px;

                &.swiper-pagination-bullet-active {
                    background-color: #0066ff;
                }
            }
        }
    }

    &-card1 {
        .background-img {
            width: 45%;
            height: 200px;
            overflow: hidden;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50%;
        }

        .solution-page-card__item {
            flex: 0 1 25%;

            img {
                padding: 0 10px;
                box-sizing: border-box;
            }
        }
    }

    &-card2 {
        .solution-page-card__item {
            width: calc((100vw - $page-padding-x-lg * 2) / 9);
        }
    }

    &-card3 {
        margin-bottom: 10px;

        .solution-page-card__item {
            width: calc((100vw - $page-padding-x-lg * 2) / 7);
        }
    }

    &-card4 {
        .solution-page-card__content {
            justify-content: flex-start;
        }

        .solution-page-card__item {
            width: calc((100vw - $page-padding-x-lg * 2) / 9);
            margin-right: calc((100vw - $page-padding-x-lg * 2 - ((100vw - $page-padding-x-lg * 2) / 9) * 6) / 5);
        }
    }

    @media (max-width: 768px) {
        &-solution {
            &__content {
                font-size: 14px;
                width: auto;
            }

            &__description {
                padding-left: $page-padding-horizontal;
                font-size: 14px;

                &-font {
                    top: 0px;
                    font-size: 16px;
                    color: #000;
                }

                .solution-page-solution__description-block {
                    margin-top: 1rem !important;
                }
            }

            &__img {
                width: 100vw;
            }
        }

        &-card {
            padding: 0 $page-padding-horizontal;

            &__title {
                >div:first-of-type {
                    span {
                        font-size: 40px;
                    }

                    h3 {
                        font-size: 20px;
                        left: 15px;
                        bottom: 3px;
                    }
                }

                p {
                    font-size: 14px;
                }
            }

            .solution-page-card__item {
                font-size: 12px;
            }
        }

        &-card1 {
            .background-img {
                width: calc(100% - 20px);
                height: 35vw;
            }

            .brand-card {
                width: calc(100% - 15px);
            }

            .solution-page-card__item {
                justify-content: flex-start;

                img {
                    padding: 0;
                }
            }
        }

        &-card2 {
            .solution-page-card__item {
                width: calc((100vw - $page-padding-x-lg * 2) / 3.5);
            }
        }

        &-card3 {
            .solution-page-card__item {
                width: calc((100vw - $page-padding-x-lg * 2) / 2.5);
            }
        }

        &-card4 {
            .solution-page-card__content {
                justify-content: space-between;
            }

            .solution-page-card__item {
                width: calc((100vw - $page-padding-x-lg * 2) / 3.5);
            }
        }
    }
}