@import "../../styles/varible";

.foot {
    background-color: #f8f8f8;
    width: 100vw;
    .remark-register{
        &:hover{
            color: #0066ff;
        }
        cursor: pointer;
    }
    &-dark{
        background-color: #eee;
        .form-input,.form-textarea{
            background: #ddd;
        }
        .foot-copyright{
            background: #ddd !important;
        }
    }
    &>div:first-child{
        padding:0 $page-padding-x-lg;
    }
    &-copyright{
        background-color: #eeeeee;
        font-size: 14px;
        color:#333;
        text-align: center;
    }
    &-btn {
        height: 40px;
       
        display: block;
        // align-items: center;
        // justify-content: center;
        
        // margin-right: calc(var(--bs-gutter-x) * 0.5);
        // margin-left: calc(var(--bs-gutter-x) * 0.5);
        .btn {
            border: 1px solid #666666;
            white-space: nowrap;
            width: 120px;
            color:#333;
        }
    }
}

.ft-12 {
    font-size: 14px;
}

.form-input {
    width: 100%;
    padding-left: 1rem;
    height: 40px !important;
    background-color: #eee;
    border: none;

    &:focus {
        outline: none !important;
        border: none !important;
        background: #eee !important;
    }

    &::-webkit-input-placeholder {
        font-size: 16px;
    }
}

.form-textarea {
    padding-left: 1rem;
    padding-top: 1rem;
    background-color: #eee;
    border: none;
    height: 88px !important;

    &:focus {
        outline: none !important;
        border: none !important;
        background: #eee !important;
    }

    &::-webkit-input-placeholder {
        font-size: 16px;
    }
}

.form-input:focus,
.form-textarea:focus {
    background-color: #fff;
    color: #000;
}

input.form-input,
textarea.form-textarea,
input.form-input::-webkit-input-placeholder,
/* input.form-input:-moz-placeholder, */
/* input.form-input:-ms-input-placeholder, */
textarea.form-textarea::-webkit-input-placeholder

/* textarea.form-textarea:-moz-placeholder,
textarea.form-textarea:-ms-input-placeholder */
    {
    font-size: var(--jc-ft-12) !important;
    color: var(--jc-color-minor) !important;
    caret-color: var(--jc-color-minor);
}

@media screen and (min-width:992px) {
    .form-textarea {
        height: 100%;
    }
    .foot>div:first-child{
        padding:0 $page-padding-x-lg;
    }
}

@media screen and (max-width:991px) {
    input.form-input,
    textarea.form-textarea,
    input.form-input::-webkit-input-placeholder,
    /* input.form-input:-moz-placeholder, */
    /* input.form-input:-ms-input-placeholder, */
    textarea.form-textarea::-webkit-input-placeholder

    /* textarea.form-textarea:-moz-placeholder,
    textarea.form-textarea:-ms-input-placeholder */
        {
        font-size: var(--jc-ft-16) !important;
        color: var(--jc-color-minor) !important;
        caret-color: var(--jc-color-minor);
    }

    .form-input,
    .form-textarea {
        font-size: var(--jc-ft-16) !important;
        height: 3rem;
    }

    .form-textarea {
        width: 100%;
        height: 5rem;
    }
}
@media screen and (max-width:768px){
    .foot-copyright{
        line-height: 1.5;
    }
    .foot>div:first-child{
        padding:0 1rem;
    }
}
.form-textarea {
    width: 100%;
}

.ft-logo {
    width: 5rem;
    height: auto;
}

.ft-icon {
    width: 1.25rem;
    height: auto;
}

div.ant-message-custom-content{
    display: flex;
    align-items: center;
}

.toast{
    position: fixed;
    left:50%;
    top: 50%;
    transform: translate(-50%,calc(-50% - 200px));
    z-index: 9999;
    max-width:250px;
    .btn-close{
        display: none;
    }
}