.custom-img {
    height: 100%;
    width: 100%;
    position: relative;

    &.banner-img {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }

    .small-img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    img {
        display: block;
        height: 100%;
        width: 100%;
    }
}