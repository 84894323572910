@import "../../styles/varible";
@import "../../styles/common.scss";

.case-page {
  &-banner {
    width: 100%;

    img {
      width: 100%;
    }
  }
  &-head {
    .cell-title-head__main {
      margin-top: -1.5rem;
    }
  }
  &-content {
    padding: 0 $page-padding-x-lg;

    > img {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    &-content {
      padding: 0 $page-padding-horizontal;

      > div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        &.flex-1 {
          img {
            width: 100%;
          }
        }

        &.flex-2 {
          img {
            width: 49%;
          }
        }

        &.flex-3 {
          > img,
          div {
            width: 49%;
          }

          > div {
            display: flex;
            flex-direction: column;

            img {
              width: 100%;
            }
          }
        }
      }
    }
    &-head {
        .header-wrapper-content--des{
            left: $page-padding-horizontal;
            right:  $page-padding-horizontal;
        }
        .cell-title-head__main {
            margin-top: -4.5rem;
          }
        .header-wrapper-content{
            background-image: url(../../assets/caseSharing/img_case_banner--app.png)!important;
        }
      }
  }
}
