@import "../../../styles/varible";
@import "../../../styles/common.scss";

$gap-padding:15px;

.join-page{
    &-title{
        position: absolute;
        //top:120px;
        top:calc(50% - 5vw);
        // top:calc(50% + 30px);
        width: calc(100vw - 200px);
        transform: translateY(-50%);
    }
    p{
        font-size: 16px;
        color: #222;
        font-weight: 345;
        margin-top: 10px;
        margin-bottom: 20px;
        padding-right: 20px;
        max-width: 963px;
    }
    &-card{
        display: flex;
        flex-wrap: wrap;
        &>div{
            flex:calc(50% - $gap-padding*2)
        }
        margin-bottom: $gap-padding;
    }
    .join-card{
        margin: $gap-padding $gap-padding 0 $gap-padding;
        width: 50vw;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        overflow: hidden;
        img{
            width: calc(50vw - $gap-padding*2);
        }
        &-title{
            padding:10px 15px;
            font-size: 14px;
            color:#333;
            font-weight: 500;
        }
    }
  
    @media(max-width:768px) {
        div.cell-title-head__main{
            margin:0 !important;   
        }
        &-title{
            position: absolute;
            top:50%;
            width: calc(100vw - 15px);
            transform: translateY(-50%);
        }
        &-content{
            padding:0 $page-padding-horizontal;
            height: $mobile-bg-height;
           
        }
        &-wrapper{
            div.header-wrapper-content{
                height: 80vw;
            }
        }
        p{
            text-align: justify;
            font-size: 12px;
            margin-top: 8px;
            margin-bottom: 10px;
        }
        &-card{
            display: flex;
            flex-wrap: wrap;
            &>div{
                flex:calc(100% - $gap-padding*2)
            }
            margin-bottom: $gap-padding;
        }
        .join-card{
            img{
                width: calc(100vw - $gap-padding*2);
            }
        }
    }
}

