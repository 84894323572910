@import "../../styles/varible";
@import "../../styles/common.scss";

.news-page {
    &-content {
        height: 100%;
    }
    &-detail{
        padding: 0 $page-padding-x-lg;
        &__btn{
            text-align: center;
            margin-bottom: 15px;
            button{
                border:1px solid #0066ff;
                font-size: 12px;
                color:#0066ff;
                background-color: #fff;
                padding:3px 20px;
                border-radius: 3px;
            }
        }
    }
    &-card{
        flex-wrap: nowrap;
        background-color: #fff;
        padding:10px 15px;
        margin: 0px 0px 15px 0px;
        border-radius: 5px;
        box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.05);
        div.carouse-content-left{
            width: 320px;
            height: 160px;
            background-size: 100% 100%;
        }
        div.carouse-content-right{
            padding-left: 15px;
            .content{
                padding-bottom: 10px;
            }
            flex-grow: 1;
        }
        button{
            margin-top: 15px;
            font-size: 14px;
            padding: 3px 10px;
        }
        .bl-2{
            font-size: 14px;
        }
        @media(max-width:768px) {
            --jc-ft-18:16px;
            margin:15px;
            a{
                display: inline-block;
            }
            div.carouse-content-left{
                width: 160px;
                height: 105px;
            }
            div.carouse-content-right{
                position: relative;
                height: 120px;
                width: calc(100% - 300px);
                padding-left: 15px;
                padding-right: 0;
                // padding-right: 50px;
                // &>div{
                //     position: absolute;
                // }
            }
        }
    }
    @media(max-width:768px) {
        &-content {
            padding: 0 $page-padding-horizontal;
            height: $mobile-bg-height;
        }

        &-detail {
            padding: 0;
        }
    }
}