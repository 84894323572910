@import "../../styles/varible";
@import "../../styles/common.scss";

#jc-video{
    width: 100vw;
    height: 30vh;
    top:70vh;
    left:0;
    object-fit: contain;
    z-index: -1;
    position: fixed;
}
.home-page {
    width: 100vw;
    position: relative;
    background-color: #fff;
    z-index: 1;
    padding-bottom: 30px;
    .carousel-item>div.row{
        margin:0;
    }
    &-banner {
        @include cover("../../assets/home/banner-bg1.webp");
        background-position: 50% calc(50% - 10px);
        &_content {
            position: absolute;
            top: 50%;
            transform: translateY(calc(-50% - 50px));
            color: #333;
            left: 9vw;
            &--f1 {
                font-size: 45px;
                line-height: 1.3;
                font-weight: 100;
                transform: translateX(-3px);

                .bold {
                    font-size: 70px;
                    font-weight: 530;
                }

                &>span:first-child {
                    margin-right: 30px;
                }
            }

            &--f2 {
                font-size: 18px;
                font-weight: 200;
                word-spacing: 15px;
            }

            &--f3 {
                width: 25vw;
                text-align: justify;
                margin: 20px 0;
                font-weight: 300;
                //transform: translateX(-1px);
            }

            &--f4 {
                display: inline-block;
                text-decoration: none;
                cursor: pointer;
                color: #333;
                padding: 5px 15px;
                border-radius: 100px;
                font-size: 12px;
                background-color: #fff;
                font-weight: 500;
            }
        }
    }

    &-video {
        width: 100vw;
        height: 100vh;
        position: relative;
        video{
            width: 100vw;
            height: 100vh;
        }
        &--bg{
            @include cover("../../assets/home/banner-bg.png");
            top:0;
            left:0;
            position: absolute;
        }
        
         &>img.play{
            width: 8vw;
            height: 8vw;
            position: absolute;
            top:50%;
            left:50%;
            transform: translate(-50%,-50%);
         }
         &>img.title{
            position: absolute;
            width: 27vw;
            top:15vh;
            left:50%;
            transform: translateX(-50%);
         }
    }
    &-link{
        a{
            color:#333;
        }
    }
    &-products {
        display: flex;
        justify-content: center;
        // padding:0 10px;
    }

    &-product {
        cursor: pointer;
        img {
            width: calc(33.3vw - 10px);
            height: calc(33.3vw - 10px);
        }

        font-size: 14px;
        color:#333;

        &>div {
            padding-left: 10px;
        }
    }

    &-carousel {
        // padding: 0 $page-padding-horizontal 28px;
        // overflow: hidden;
        div.carouse-content-left{
            width: calc(40vw - 15px);
            height: 25vw;
        }
    }

    .carousel .carousel-indicators {
        margin-bottom: 0;
        bottom: -50px;
    }

    [data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target],
    [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
        background-color: #ccc;

        &.active {
            background-color: #0066ff;
        }
    }
    &-solution{
        &__content{
            width: calc(100vw - $page-padding-x-lg*2);
            font-size: 14px;
            text-align: justify;
        }
        &-link{
            a{
                color:#333;
                font-size: 16px;
                margin-left: 5px;
            }
        }
        &__description{
            font-size: 16px;
            color:#333;
            &-block{
                background-color: #1E4A8E;
                border-radius: 12px;
                display: inline-block;
                padding:8px 15px;
                margin-top:5px;
                color:#fff;
            }
        }
        &__img{
            width: 100vw;
        }
    }
    @media(min-width:769px){
        .page-full{
            width: 100vw;
            height: 100vh;
            padding-top:10px;
            div.cell-title{
                margin-top:0;
            }
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        &-carousel {
            padding: 0 $page-padding-x-lg 80px;
            .carouse-content.row{
                flex-wrap: nowrap;
            } 
            div.carouse-content-right{
                padding-left: 15px;
            }
        }
       
        &-link{
            right: 100px;
         }
        &-solution{
            &__description{
                padding:0 $page-padding-x-lg !important;
            }
        }
        &-news-wrapper{
            div.cell-title{
                margin-top:20px;
            }
        }
        &-solution-wrapper{
            padding-top:20px;
            div.cell-title{
                margin-top:0px;
            }
        }
    }
    @media(max-width:768px) { 
        #jc-video{
            object-fit: fill;
        }
        &-solution{
            &__content{
                font-size: 14px;
                width: auto;
            }
            &-link{
                a{
                    color:#333;
                    font-size: 16px;
                    margin-left: 10px;
                }
            }
            &__description{
                padding:0;
            }
            &__img{
                width: 100vw;
            }
            &-wrapper{
                padding-top:30px;
            }
        } 
        &-carousel {
            padding: 0 15px;
            .carouse-content-left{
                width: calc(100vw - $page-padding-horizontal*2) !important;
                height: 50vw !important;
            }
            .carouse-content-right{
                padding-right: 0 !important;
            }
        }
        &-banner {
            width: 100vw;
            height: 70vw;
            background-image: url(../../assets/home/banner-bg1.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50%;

            &_content {
                // position: absolute;
                // top:50%;
                transform: translateY(-50%);
                left: 20px;

                &--f1 {
                    font-size: 22px;
                    font-weight: 300;

                    .bold {
                        font-size: 28px;
                        font-weight: 600;
                    }

                    &>span:first-child {
                        margin-right: 20px;
                    }
                }

                &--f2 {
                    font-size: 12px;
                    word-spacing: 0px;
                }

                &--f3 {
                    width: 36vw;
                    font-size: 12px;
                    text-align: justify;
                    margin: 10px 0 15px;
                    //  font-weight: 300;
                }

                &--f4 {
                    color: #333;
                    padding: 5px 15px;
                    border-radius: 100px;
                    font-size: 12px;
                    background-color: #fff;
                }
            }
        }

        &-video {
            width: 100vw;
            height: 70vw;
            // background-image: url(../../assets/home/banner-bg.png);
            background-repeat: no-repeat;
            // background-size: 100% 100%;
            background-position: 50%;
            background-size: cover;
            &--bg{
                height: 70vw;
            }
            &>img.play{
                width: 20vw;
                height: 20vw;
            }
            &>img.title{
                width: 40vw;
                top:7vw;
            }
        }

        &-swiper {

            margin: 0 $page-padding-horizontal;
            width: calc(100vw - $page-padding-horizontal*2);
            // padding: 0 15px 10px 0;
            // &>div{
            //     overflow-x: hidden;
            // }
        }

        &-product {
            img {
                width: 50vw;
                height: 50vw;
            }

            font-size: 12px;
            color:#333;

            &>div {
                padding-left: 5px;
            }
        }

        .carousel .carousel-indicators {
            margin-bottom: 0;
            bottom: -43px;
        }
       
    }
}