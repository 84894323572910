.carouse-content{
    cursor: pointer;
    &-right{
        padding:0 1.5rem;
    }
    &>div{
        padding:0;
        &:first-child{
            width: 500px;
            height: 280px;
            overflow: hidden;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50%;
        }
        &:last-child{
           flex-grow: 1;
        }
    }
    // .content{
    //     padding-bottom: 1rem;
    // }
}
@media(max-width:768px) {
    .carouse-content{
        &-right{
            padding:0 1.5rem;
        }
        padding:0;
        a{
            display: none;
        }
    }
}