@import "../../../styles/varible";
@import "../../../styles/common.scss";

$gap-padding:15px;

.join-employ-page{
    &-title{
        position: absolute;
        top:50%;
        // width: calc(100vw - $page-padding-x-lg*2);
        left:50%;
        transform: translate(-50%,-50%);
    }
    &-content{
        position: relative;
        &--detail{
            position: absolute;
            left:50%;
            transform: translateX(-50%);
            bottom:30vh;
        }
        &__info{
            padding:0 $page-padding-x-lg;
            &--main{
                font-weight: 530;
                font-size: 20px;
                span{
                    margin-right: 20px;
                }
            }
            &--tag{
                font-size: 14px;
                display: flex;
                color:#666;
                margin:5px 0 20px;
                &>div{
                    padding:0 10px;
                    position: relative;
                    &::after{
                        content: '';
                        position: absolute;
                        right:0;
                        height: 80%;
                        top:50%;
                        transform: translateY(-50%);
                        width: 1px;
                        background-color: #666;
                    }
                }
                &>div:first-child{
                    padding-left: 0;
                }
            }
        }   
        &--require{
            font-size: 14px;
            p{
                margin:0;
                margin-bottom: 8px;
            }
        }
        &--btn{
            font-size: 14px;
            display: inline-block;
            margin: 30px 0 20px;
            padding: 5px 25px;
            border-radius: 5px;
            color:#fff;
            background-color: #0066ff;
        }
        &--wrapper{
            position: relative;
            &__popover{
                position: absolute !important;
                bottom: 10px;
                left:90px;
                .detail{
                    font-size: 12px;
                    color:#333;
                    span{
                        color:#666;
                    }
                }
            }
        }
    }
    p{
        font-size: 16px;
        color: #222;
        font-weight: 345;
        margin-bottom: 5px;
        width: 100%;
        width: 800px;
    }
    @media(max-width:768px) {
        div.cell-title-head__main{
            margin:0 !important;   
        }
        &-wrapper{
            div.header-wrapper-content{
                height: 80vw;
            }
        }
        &-content{
            padding:0 $page-padding-horizontal;
            height: $mobile-bg-height;
            &__info{
                padding:0 $page-padding-horizontal;
            }
            &--detail{
                position: absolute;
                left:50%;
                top:50%;
                transform: translateX(-50%);
                width: calc(100vw -  $page-padding-horizontal*2);
                bottom:70px;
            }   
        }
        p{
            text-align: justify;
            font-size: 12px;
            margin-top: 8px;
            margin-bottom: 10px;
            width: auto;
        }
    }
}

