.float-dot{
    position: fixed;
    display: flex;
    flex-direction: column;
    top:50%;
    transform: translateY(-50%);
    right: 30px;
    z-index: 1;
    &>div{
        width: 60px;
        height: 60px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: relative;
        cursor: pointer;
    }
    &-hidden{
        visibility: hidden;
    }
    @for $i from 1 through 6 {
        &-#{$i}{
            &:hover{
                .code{
                    display: block;
                }
            }
            &>div:first-child{
                width: 100%;
                height: 100%;
                background-image: url(../../assets/float-dot/dot#{$i}.png); 
                background-position: center center;
                background-size: 100% 100%;
                position: relative;
                z-index: 5;
                &::after{
                    content: '';
                    position: absolute;
                    width: 40px;
                    height: 40px;
                    top:50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                }
            }
            .code{
                position: absolute;
                width: 110px;
                height: 110px;
                right: 70%;
                top:50%;
                transform: translateY(-50%);
                padding-right: 20px;
                box-sizing: content-box;
                img{
                    width: 110px;
                    height: 110px;
                }
                display: none;
            }
        }
    }
}