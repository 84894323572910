@import "./varible";
@import "./common.scss";

@font-face {
    font-family: "思源黑体 Normal";
    src: url('../assets/fonts/font-normal/webfont.eot');
    /* IE9*/
    src: url('../assets/fonts/font-normal/webfont.eot?t#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../assets/fonts/font-normal/webfont.woff') format('woff'),
        /* chrome, firefox */
        url('../assets/fonts//font-normal/webfont.ttf') format('truetype');
}

:root {
    --jc-primary: #0066ff;
    --jc-primary-secondary: #2A7FFF;
    --jc-primary-secondary1: #1573FF;
    --jc-color-secondary: #F5F5F5;
    --jc-color-minor: #545556;
    --jc-color-hinor: #2E2F30;
    --jc-yellow: #ffBB02;
    --jc-white: #fff;
    --jc-black: #000;
    --jc-black-rgb: 0, 0, 0;
    --jc-black-10: rgba(0, 0, 0, 0.1);
    --jc-black-25: rgba(0, 0, 0, 0.25);
    --jc-black-50: rgba(0, 0, 0, 0.5);
    --jc-black-85: rgba(0, 0, 0, 0.85);
    --jc-bold-500: 500;
    --jc-bold-600: bold;
    --jc-ft-90: 5.6rem;
    --jc-ft-72: 4.5rem;
    --jc-ft-50: 3.125rem;
    --jc-ft-48: 3rem;
    --jc-ft-48: 3rem;
    --jc-ft-40: 2.5rem;
    --jc-ft-36: 2.25rem;
    --jc-ft-26: 1.625rem;
    --jc-ft-24: 1.5rem;
    --jc-ft-20: 1.25rem;
    --jc-ft-18: 1.12rem;
    --jc-ft-16: 1rem;
    --jc-ft-14: 0.87rem;
    --jc-ft-12: 0.75rem;
    --jc-line-height: 2;
    /* 所需单边样式线的厚度 */
    --jc-line-thickness: 1px;
    --jc-line-thickness-secondary: 0.28rem;
    /* 单边线距离顶部方向的距离 */
    --jc-line-top-distance: 2px;
    --jc-container-pc-width: 80vw;
    --jc-container-mobile-width: 90vw;
    --jc-nav-height: 85px;
}

a {
    outline: 0px;
    -moz-user-select: none;
    /*mozilar*/
    -webkit-user-select: none;
    /*webkit*/
    -ms-user-select: none;
    /*IE*/
    user-select: none;
    text-decoration: none;
}


body {
    @include scroll;
    overflow-x: hidden;
    background: #F8F8F8;
}

.navbar {
    --bs-navbar-padding-x: 0;
    --bs-navbar-padding-y: 0;
    --bs-navbar-toggler-focus-width: 0;
    --bs-bg-opacity: 0;
    --bs-navbar-brand-padding-y: 0;

    .nav-link:focus-visible {
        box-shadow: none;
    }

    .navbar-toggler {
        border: none;
    }

    .navbar-toggler-icon {
        width: 1.4em;
    }
}

.nav-link {
    --bs-nav-link-color: #333;
    --bs-navbar-hover-color: #333;
    --bs-emphasis-color-rgb: #333;
    --bs-nav-link-font-weight: 400;
    color: #333 !important;
    white-space: nowrap;
    position: relative;
}

.navbar-brand {
    img {
        height: 40px;
        cursor: pointer;
    }
}

.filter-backdrop {
    @include filter-backdrop;
}

div.menus-pc-show.other-route-nav,
nav.other-route-nav {
    background: #FFFFFF !important;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
    -webkit-backdrop-filter: blur(0px) !important;
    backdrop-filter: blur(0px) !important;
    opacity: 1;
}

nav.other-route-nav--offshadow,
div.other-route-nav--offshadow,
.other-route-nav--offshadow {
    // background: #FFFFFF !important;
    @include filter-backdrop;
    box-shadow: none !important;
}


@media (min-width: 768px) {
    .nav-root {
        // &:hover{
        //     -webkit-backdrop-filter: blur(5px) !important;
        //     backdrop-filter: blur(5px) !important;
        //     background-color: #fff;
        // }
    }

    .navbar .nav-link,
    .navbar .nav-item {
        // padding-left: 0 !important;
        // padding-right: 0 !important;
        padding: 0 !important;
        margin-left: var(--bs-navbar-nav-link-padding-x);
        margin-right: var(--bs-navbar-nav-link-padding-x);
    }

    .navbar .nav-item>a.nav-link {
        margin: 0;
    }

    .m-page-t {
        margin-top: 60px;
    }

    .filter-backdrop-none:not(.filter-backdrop-event) {
        background-color: transparent;
        //  background-color: #fff;
        -webkit-backdrop-filter: blur(0px);
        backdrop-filter: blur(0px);
    }

    .nav-root {
        padding-left: $page-padding-x-lg;
        padding-right: $page-padding-x-lg;
    }

    .navbar-brand img {
        height: $navbar-height-md;
    }

    .navbar .dropdown {
        a:after {
            display: none;
        }

        div.dropdown-menu.show {
            display: none;
        }
    }

    .nav-link,
    .nav-item {
        --bs-nav-link-font-size: 16px;
        --bs-navbar-nav-link-padding-x: 1.8vw;

        &:hover {
            font-weight: 550;
        }

        &.focus {
            font-weight: 550;

            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 2px;
                background-color: #333;
                left: 50%;
                transform: translateX(-50%);
                bottom: -5px;
            }

            a.nav-link {
                font-weight: 550 !important;
            }
        }

        &.active {
            font-weight: 550;
        }
    }



    .container {
        padding: 0
    }
}

@media(max-width:768px) {

    .filter-backdrop {
        -webkit-backdrop-filter: blur(0px);
        backdrop-filter: blur(0px);
        background-color: #fff;
    }

    #root {
        padding-top: 56px;
        background: transparent;
    }

    .container {
        --bs-gutter-x: 1.5rem;
        // --bs-gutter-y:1.2rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    a:focus {
        color: #333;
    }

    .navbar-nav {
        padding: 0 1.5rem 8px;

        .nav-link {
            --bs-nav-link-padding-y: 5px;
            // font-size: 15px;
            -moz-user-select: none;
            /*mozilar*/
            -webkit-user-select: none;
            /*webkit*/
            -ms-user-select: none;
            /*IE*/
            user-select: none;
        }

        .nav-link__underline.nav-link,
        .nav-link__underline>.nav-link {
            border-bottom: 1px solid #eee;
        }

        .dropdown-item {
            &:hover {
                background: transparent;
            }

            -moz-user-select: none;
            /*mozilar*/
            -webkit-user-select: none;
            /*webkit*/
            -ms-user-select: none;
            /*IE*/
            user-select: none;
        }

        &>.nav-link,
        &>.nav-item {
            font-size: 15px;
        }

        //移动端二级菜单
        &>.nav-item>.dropdown-menu {
            background: transparent;
            border: none;
            padding: 0;
            font-size: 14px;

            &>.nav-item {
                padding-left: 15px;

                //移动端三级菜单
                &>.dropdown-menu {
                    background: transparent;
                    border: none;
                    padding: 0;
                    font-size: 13px;
                }
            }
        }

        // div.dropdown-menu {
        //     background: transparent;
        //     border: none;
        //     padding: 0;

        //     a.dropdown-item {
        //         font-size: 14px;
        //     }
        //     &>div.dropdown-menu{
        //         border:1px solid red;
        //         a.dropdown-item {
        //             font-size: 14px;

        //         }
        //     }
        // }

        .dropdown-toggle {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &::after {
                border: none;
                height: 8px;
                width: 8px;
                border-width: 1px 1px 0 0;
                border-color: #333;
                border-style: solid;
                transform: rotate(135deg);
            }

            &.show {
                &::after {
                    transform: rotate(-45deg);
                }
            }
        }
    }

}

@media (min-width: 576px) {

    .container-sm,
    .container {
        max-width: none;
    }
}

//全局样式
.bl-1 {
    @include ellipse(1)
}

.bl-2 {
    @include ellipse(2)
}

.bl-3 {
    @include ellipse(3)
}

.bl-4 {
    @include ellipse(4)
}

//字体公共样式
.ft-14 {
    font-size: 14px;
}

.ft-primary-color {
    color: #333;
}

.ftcolor-black50 {
    color: var(--jc-black-50);
}

/* 下侧水平方向线条 */
.lh-b {
    position: relative;
}

.lh-b::after {
    content: '';
    position: absolute;
    width: 2rem;
    height: var(--jc-line-thickness);
    background-color: var(--jc-primary);
    bottom: 0;
    left: 0;
}

.ftbold-18 {
    font-size: calc(var(--jc-ft-18));
    font-weight: var(--jc-bold-600);
}

/* 全局按钮定制蓝色样式 */
.btn-primary-blue {
    border-radius: 0;
    background-color: var(--jc-primary);
    font-size: calc(var(--jc-ft-16));
    /* 12px 45px */
    /* padding:calc(0.32rem + 0.41vw) calc(1.2rem + 1.6vw);   */
    padding: 0.7rem 2rem;
    color: var(--jc-white);

    &__ghost {
        background-color: transparent;
        border: 1px solid var(--jc-primary);
        border-radius: 4px;
        color: var(--jc-primary);

        &:hover {
            background-color: transparent;
            border: 1px solid var(--jc-primary);
            border-radius: 4px;
            color: var(--jc-primary);
        }
    }
}

.mt-jc-4 {
    margin-top: 4rem;
}

.ftcolor-white {
    color: var(--jc-white);
}

//   .ft-12{
//     font-size: calc(var(--jc-ft-12));
//   }
// .btn-primary-blue:hover {
//     color: var(--jc-white);
// }