@import "../../styles/varible";
@import "../../styles/common.scss";

$menus-padding-hr:calc(9vw + 60px);
$meuns-block-pt:15px;
$meuns-block-pb:30px;


.menus-pc {
    padding-top: $navbar-height-md;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 2;
    &-hidden {
        max-height: 0;
        overflow: hidden;
    }
    &.about{
        ul{
            justify-content: flex-end;
        }
    }
    &>div{
        padding-top: $meuns-block-pt;
        padding-bottom: $meuns-block-pb;
    }
    &-show {
        // max-height: 480px;
        overflow: hidden;
        &>div{
            @include filter-backdrop;
        }
       
        .menus-pc-child {
            -webkit-transition: max-height .5s;
            transition: max-height .5s;
            padding-top: 8px;
            &>a {
                font-size: 16px;
                white-space: nowrap;
                text-decoration: none;
                color: #666;

                &:hover {
                    text-decoration: underline;
                    font-weight: 530;
                }
            }

         
            padding-left:$menus-padding-hr;
            padding-right: $menus-padding-hr;

            ul {
                padding: 0;
                margin: 0;
            }

            li {
                list-style: none;
            }

            &.menus-pc-child__render {
                padding-top: $meuns-block-pt;
                padding-bottom: $meuns-block-pb;
                ul {
                    display: flex;
                    flex-wrap: wrap;
                    max-width: 35vw;
                    min-height: 100px;

                    &>li {
                        flex-basis: 50%;

                        a {
                            font-size: 16px;
                            white-space: nowrap;
                            text-decoration: none;
                            color: #666;

                            &:hover {
                                text-decoration: underline;
                                font-weight: 530;
                            }
                        }
                    }
                }
            }
        }

        &.product {
            .menus-pc-child {
                display: flex;
                gap: 5vw;

                h3 {
                    margin: 0;
                    font-size: 16px;
                    color: #222;
                    font-weight: 500;
                    padding-bottom: 8px;
                    margin-bottom: 8px;
                    border-bottom: 1px solid #ccc;
                }

                &__content {
                    display: flex;
                    gap: 6vw;

                    h4 {
                        margin: 0;
                        font-size: 16px;
                        font-weight: 500;
                    }

                    &--detail li {
                        margin: 10px 0;

                        a {
                            font-size: 14px;
                            white-space: nowrap;
                            text-decoration: none;
                            color: #666;

                            &:hover {
                                text-decoration: underline;
                                font-weight: 530;
                            }
                        }
                    }
                }
            }
            // ul.menus-pc-child{
            //     padding-bottom: 600px;
            //     border: 1px solid red;
            // }
            // .menus-pc-child__main{
            //     padding-bottom: 0 !important;
            //     padding-right: 9vw !important;
            //     padding-top: $meuns-block-pt;
            //     &>div{
            //         border-bottom: 1px solid #ccc;
            //         display: block !important;
            //         width: 100%;
            //         padding-bottom: 15px;
            //         margin-bottom: 10px;
            //     }
            //     a{
            //         font-size: 18px;
            //         font-weight: 530;
            //         color: #222;
            //     }
            //     &>div{
            //         border-bottom: 1px solid #ccc;
            //     }
               
            //     padding-bottom: 8px;
            // }
        }
    }
}

@media(max-width:768px) {
    .menus-pc {
        display: none !important;
    }
}