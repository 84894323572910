@import "../../../styles/varible";
@import "../../../styles/common.scss";

.introduce-page {
    &-content {
        @include cover("../../../assets/about/img_about-bg.webp");
        padding: 0 $page-padding-x-lg;

        &-title {
            background: rgba(255, 255, 255, 0.5);
            border-radius: 16px;
            padding:40px;
            position: absolute;
            top:calc(50%  + 30px - 1vw);
            width: calc(100vw - $page-padding-x-lg*2);
            left:50%;
            transform: translate(-50%, -50%);
            div.cell-title-head__main{
                margin-top: 0;
            }
            &>p {
                color: #222 !important;
                // font-weight: 470 !important;
            }

           
        }

        .cell-title-head__main {
            margin-top: 100px;
        }
    }

    &-display {
        img {
            width: 100vw;
        }

        &--pc {
            padding: $page-padding-horizontal $page-padding-x-lg;

            &>img {
                width: calc(100vw - $page-padding-x-lg*2);
            }
        }
    }

    p {
        font-size: 16px;
        color: #222;
        font-weight: 345;
        margin-top: 10px;
        margin-bottom: 20px;
      //  max-width: 963px;
        text-align: justify;
        line-height: 1.9;
        &:last-child{
            margin-bottom: 0;
        }
    }
    @media(max-width:768px) {
        &-content {
           // padding: 0 $page-padding-horizontal;
            height: 120vw;
            padding: 0;
            .cell-title-head__main {
                margin-top: 15px;
            }
            &-title{
                left: 0;
                transform: translate(0px, 0px);
                top: 15px;
                width: calc(100vw - 30px);
                box-sizing: border-box;
                margin-left: 15px;
                padding:15px;
            }
        }

        &-display-mobile {
            padding: $page-padding-horizontal;

            img {
                width: calc(100vw - $page-padding-horizontal*2);
            }
        }

        p {
            text-align: justify;
            font-size: 12px;
            margin-top: 8px;
            margin-bottom: 10px;
            line-height: 1.4;
        }
    }
}