@import "../../styles/varible";
@import "../../styles/common.scss";

.header-wrapper {
  padding-top: 60px;
  &-ptnone {
    padding-top: 0px;
  }
  &-content {
    width: 100vw;
    position: relative;
    background-size: 0% 0%;
    .custom-img{
      width: 100vw;
      max-height: 100vh; 
    }
    & > img {
      width: 100vw;
      max-height: 100vh;
    }
    &--title {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      padding: 0 $page-padding-x-lg;
    }
    &--des {
    //   width: 66.7vw;
      margin: 0 auto;
      position: absolute;
      left: 16.7vw;
      right: 16.7vw;
      color: #666;
      font-size: 17px;
      bottom: 1vw;
    }
  }

  @media screen and (max-width: 768px) {
    padding-top: 0px;
    &-content {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50%;
      height: $mobile-bg-height;
      width: 100vw;
      &--title {
        padding: 0 $page-padding-horizontal;
      }
      &--des {
        font-size: 12px;
      }
    }
  }
}
