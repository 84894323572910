.return-back{
    position: fixed;
    top:15vw;
    left:30px;
    width: 60px;
    height: 60px;
    img{
        width: 60px;
        height: 60px;
    }
    z-index: 10;
}

@media screen and (max-width:768px) {
    .return-back{
        left:auto;
        right:15px !important;
        top:15vh;
    }
}