$arrow-size:6px;

.jc-bubble {
    position: relative;
    display: inline-block;
    background-color: #fafafa;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: var(--arrow-size,$arrow-size);
    box-sizing: border-box;
    &-hidden{
        display: none !important;
    }
    &-over{
        position: absolute;
        top:0;
        width: 100%;
        left:0;
        height: 100%;
        background-color: #fff;
        z-index: 3;
        border-radius: 4px;
    }
    &-close{
        position: absolute;
        z-index: 10;
        padding: 0 5px;
        top:0px;
        right:0px;
        cursor: pointer;
        img{
            width: 15px;
            height: 15px;
        }
    }
    &::after {
        content: "";
        position: absolute;
        top: 70%;
        // right: -8px;
        left:- $arrow-size;
        width: 0;
        height: 0;
        border-top: var(--arrow-size,$arrow-size) solid #fff;
        border-bottom: var(--arrow-size,$arrow-size) solid #fff;
        border-left: var(--arrow-size,$arrow-size) solid #fff;
        border-right: var(--arrow-size,$arrow-size) solid #fff;
        transform: rotate(-135deg);
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
        border-radius: 0 0 0 4px;
        z-index: 1;
    }

    &-word {
        position: relative;
        z-index: 5;
    }
}