@import "../../styles/varible";

.jc-tab{
    margin:var(--jc-tab-mb,20px) var(--jc-tab-mx,$page-padding-horizontal);
    padding:0 var(--jc-tab-px, calc($page-padding-x-lg - $page-padding-horizontal));
    position: relative;
    padding-bottom: 1px;
    // margin-bottom:var(--jc-tab-mb,15px);
    color:#222;
    display: flex;
    background: #F8F8F8;
    &:after{
        content:'';
        position: absolute;
        left:0;
        width: 100%;
        height: 1px;
        background-color: #ccc;
        bottom: 0px;
    }
    a.nav-link{
        color:#666 !important;
        margin-right: 20px;
        border: none !important;
    }
    .nav-underline .nav-link.active{
        position: relative;
        border:none;
        &::after{
            content: "";
            left: 0;
            width: 100%;
            position: absolute;
            height: 3px;
            background-color: #333;
            bottom: -1px;
            z-index: 1;
            border-radius: 6px;
        }
    }
    a.nav-link.active{
        color:#222 !important;
    }
    @media screen and (max-width:768px) {
        padding:0 5px;
        a.nav-link{
            margin: 0 !important;
        }
    }
}